import React, {Component, Fragment} from 'react'
import Loader from './components/UI/Loader/Loader'
import ReactModal from 'react-modal';

//import Zoom from 'react-reveal/Zoom';
//import Fade from 'react-reveal/Fade';


import 'normalize-css'
import './scss/preset.scss'
import './scss/App.scss';
import './scss/media.scss';

ReactModal.setAppElement('#root');

class App extends Component {
  //DATA=========================================
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      windowState: {
        width: 0,
        height: 0
      },
      test: 0,
      showModal: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

//METHODS=========================================

  scrollToElement(e, offset = -48) {
    e.preventDefault()
    const yOffset = offset;
    const element = document.querySelector(e.target.name)
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  scrollToTop() {
    document.querySelector('html').scrollTop = 0
  }

  handleOpenModal() {
    this.setState({showModal: true});

    // setTimeout(() => {
    //   this.controlVideo('playVideo')
    // }, 1000)
  }

  activeVideoSet(n) {
    this.setState({activeVideo: n});
  }


  handleCloseModal() {
    this.setState({showModal: false});

    // this.controlVideo('pauseVideo')
  }

  controlVideo(vidcontrol) {
    let div = document.getElementById("videoWrapper");

    let iframes = div.getElementsByTagName("iframe");
    // iframes.forEach((iframe, index) => {
    //   iframe[index].contentWindow.postMessage('{"event":"command","func":"' + vidcontrol + '","args":""}', '*');
    // })

    for (const [key] of Object.entries(iframes)) {
      iframes[key].contentWindow.postMessage('{"event":"command","func":"' + vidcontrol + '","args":""}', '*');

    }
  }


  ytRender(id) {
    return <iframe width="1280" height="640"
                   src={`https://www.youtube.com/embed/${id}?enablejsapi=1&rel=0`}
                   title="YouTube video player" frameBorder="0"
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                   allowFullScreen></iframe>
  }

  handleResize = () => {
    this.setState(prevState => ({
      windowState: {                   // object that we want to update
        ...prevState.windowState,    // keep all other key-value pairs
        width: window.innerWidth,       // update the value of specific key
        height: window.innerHeight,       // update the value of specific key
      }
    }))
  }


  componentDidMount() {
    document.querySelector('body').classList.add('loading1')
    //
    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };
    window.onload = function () {
      setTimeout(function () {
        var elements = document.getElementsByClassName('typewrite');

        for (var i = 0; i < elements.length; i++) {

          var toRotate = elements[i].getAttribute('data-type');
          var period = elements[i].getAttribute('data-period');
          if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
          }
        }
      }, 1500)
    }

    /*   const script = document.createElement("script");

       script.src = "https://cdn.viqeo.tv/js/vq_starter.js";
       script.async = true;

       setTimeout(function () {
         document.body.appendChild(script);
       }, 1000)*/
    //
    //получить размер экрана
    this.handleResize()

    setTimeout(() => {
      this.setState({loading: false})
      document.querySelector('body').classList.remove('loading1')
    }, 1000)
  }

  //VIEW=========================================
  render() {
    return (
      <Fragment>
        {
          this.state.loading
            ? <Loader/>
            : null
        }


        {
          !this.state.loading
            ? <div id="contentInner">
              <section id="top">
                <div className="container">
                  {/*<a target="_blank" href="https://orda.kz/" rel="noreferrer" className="typewrite"*/}
                  {/*data-period="4000000000000"*/}
                  {/*data-type='[ "ORDA." ]'>*/}
                  {/*<span className="wrap"></span>*/}
                  {/*</a>*/}

                  <a className="logo" target="_blank" href="https://orda.kz/" rel="noreferrer">
                    <img src="./img/logo.png" alt="logo"/>
                  </a>

                  <h1>
                    orda.kz
                    <span>Media-kit</span>
                  </h1>

                  <div className="down">
                    <img name="#section1" onClick={(event) => {
                      this.scrollToElement(event, 0)
                    }} src="./img/arrow_down.png" alt=""/>
                  </div>
                </div>
              </section>
              {/*.top*/}
              <section id="section1">
                <div className="container">
                  <div className="info">
                    <img src="./logo_white.png" alt="logo" className="logo"/>
                    <h2>ORDA. — новое независимое медиа в Казахстане.</h2>
                    <p className="desc">В фокусе внимания журналистов ORDA. — события в стране и мире.
                      Мы информируем, рассказываем, показываем и комментируем. Наш главный язык — это
                      правда.
                    </p>
                    <div className="flex flex1">
                      <div className="item">
                        <div className="number">2 500 000</div>
                        <div className="txt">Ежемесячное кол-во
                          визитов
                        </div>
                      </div>
                      <div className="item">
                        <div className="number">4 150 000</div>
                        <div className="txt">Кол-во просмотров
                          страниц
                        </div>
                      </div>
                      <div className="item">
                        <div className="number">2 :05</div>
                        <div className="txt">Среднее время на
                          сайте
                        </div>
                      </div>
                    </div>
                    <div className="flex flex2">
                      <div className="item">
                        <a href="https://www.instagram.com/ordakazakhstan/" rel="noreferrer"
                           target="_blank" className="soc">
                          <img src="./img/instagram_icon.png" alt="instagram_icon"/>
                          <span>@ordakazakhstan</span>
                        </a>
                        <div className="number">258 000</div>
                        <div className="txt">подписчиков</div>
                      </div>
                      <div className="item">
                        <a href="https://www.youtube.com/c/ORDANews" rel="noreferrer"
                           target="_blank" className="soc">
                          <img src="./img/yt_icon.png" alt="yt_icon"/>
                          <span>«OrdaNews»</span>
                        </a>
                        <div className="number">217 000</div>
                        <div className="txt">подписчиков</div>
                      </div>
                      <div className="item">
                        <a href="https://www.facebook.com/ordakazakhstan/" rel="noreferrer"
                           target="_blank" className="soc">
                          <img src="./img/facebook_icon.png" alt="facebook_icon"/>
                          <span>@ordakazakhstan</span>
                        </a>
                        <div className="number">4500</div>
                        <div className="txt">подписчиков</div>
                      </div>
                      <div className="item">
                        <a href="https://t.me/orda_kz" rel="noreferrer"
                           target="_blank" className="soc">
                          <img src="./img/telegram_icon.png" alt="telegram_icon"/>
                          <span>@orda_kz</span>
                        </a>
                        <div className="number">155 000</div>
                        <div className="txt">подписчиков</div>
                      </div>
                    </div>
                    <h3>ВОЗРАСТ АУДИТОРИИ</h3>
                    <div className="flex flex3">
                      <div className="item">
                        <div className="table">
                          <div className="flex">
                            <div>18-24</div>
                            <div>9,63 %</div>
                          </div>
                          <div className="flex">
                            <div>25-34</div>
                            <div>16,19 %</div>
                          </div>
                          <div className="flex">
                            <div>35-44</div>
                            <div>21,22 %</div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="table">
                          <div className="flex">
                            <div>45-54</div>
                            <div>17,98 %</div>
                          </div>
                          <div className="flex">
                            <div>55-64</div>
                            <div>23,05 %</div>
                          </div>
                          <div className="flex">
                            <div>65+</div>
                            <div>11,93 %</div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="number">
                          40/60
                        </div>
                        <div className="txt">
                          % ж/м аудитории
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*.info*/}
                  <div className="map">
                    <img src="./img/map.png" alt="map"/>
                  </div>
                </div>
              </section>
              {/*.section1*/}
              <section id="section2">
                <div className="container">
                  <img src="./logo192.png" alt="logo192" className="logo"/>

                  <h2>АДАПТИВНЫЕ БАННЕРЫ</h2>

                  <h3>FULL BRANDING</h3>
                  <div className="price flex">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1cMUpE2PvDJs9gRxWLBKoJGsOMiQ9LUl25HCs5ZoiqcE/edit?usp=sharing"
                      target="_blank" rel="noreferrer">ПРАЙС</a>
                    <a
                      href="https://docs.google.com/document/d/15OsbCeUoUP_5TQkpemrKaBZIe-bg2UKIKfokQqs7xUM/edit?usp=sharing"
                      target="_blank" rel="noreferrer">Т Т</a>
                  </div>
                  <div className="flex example">
                    <div className="item">
                      <div className="title">Пример Desktop</div>
                      <div className="desc">( состоит из: Desktop - Background, Desktop - Right
                        banner,
                        Desktop - Central banner)
                      </div>
                      <img src="./img/m2.jpg" alt="m2"/>
                    </div>
                    <div className="item">
                      <div className="title">Пример Mobile</div>
                      <div className="desc">( состоит из: Mobile - Interscroller,
                        Mobile - Right banner )
                      </div>
                      <img src="./img/m3.jpg" alt="m3"/>
                    </div>
                  </div>

                  <hr/>

                  <h3>BILLBOARD</h3>
                  <div className="price flex">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1cMUpE2PvDJs9gRxWLBKoJGsOMiQ9LUl25HCs5ZoiqcE/edit?usp=sharing"
                      target="_blank" rel="noreferrer">ПРАЙС</a>
                    <a
                      href="https://docs.google.com/document/d/15OsbCeUoUP_5TQkpemrKaBZIe-bg2UKIKfokQqs7xUM/edit?usp=sharing"
                      target="_blank" rel="noreferrer">Т Т</a>
                  </div>
                  <div className="flex example">
                    <div className="item">
                      <div className="title">Desktop - Billboard banner 1
                      </div>

                      <img src="./img/m4.jpg" alt="m4"/>
                    </div>
                    <div className="item">
                      <div className="title">Mobile -
                        Billboard banner 1
                      </div>
                      <img src="./img/m5.jpg" alt="m5"/>
                    </div>
                  </div>

                  <hr/>

                  <h3>RIGHT BANNER</h3>
                  <div className="price flex">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1cMUpE2PvDJs9gRxWLBKoJGsOMiQ9LUl25HCs5ZoiqcE/edit?usp=sharing"
                      target="_blank" rel="noreferrer">ПРАЙС</a>
                    <a
                      href="https://docs.google.com/document/d/15OsbCeUoUP_5TQkpemrKaBZIe-bg2UKIKfokQqs7xUM/edit?usp=sharing"
                      target="_blank" rel="noreferrer">Т Т</a>
                  </div>
                  <div className="flex example">
                    <div className="item">
                      <div className="title">Desktop - Right banner</div>

                      <img src="./img/m6.jpg" alt="m6"/>
                    </div>
                    <div className="item">
                      <div className="title">Mobile -
                        Right banner
                      </div>
                      <img src="./img/m7.jpg" alt="m7"/>
                    </div>
                  </div>

                  <hr/>

                  <h3>FULL SCREEN BANNER</h3>
                  <div className="price flex">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1cMUpE2PvDJs9gRxWLBKoJGsOMiQ9LUl25HCs5ZoiqcE/edit?usp=sharing"
                      target="_blank" rel="noreferrer">ПРАЙС</a>
                    <a
                      href="https://docs.google.com/document/d/15OsbCeUoUP_5TQkpemrKaBZIe-bg2UKIKfokQqs7xUM/edit?usp=sharing"
                      target="_blank" rel="noreferrer">Т Т</a>
                  </div>
                  <div className="flex example">
                    <div className="item">
                      <div className="title">Desktop - Fullscreen banner</div>

                      <img src="./img/m8.jpg" alt="m8"/>
                    </div>
                    <div className="item">
                      <div className="title">Mobile -
                        Fullscreen banner
                      </div>
                      <img src="./img/m9.jpg" alt="m9"/>
                    </div>
                  </div>

                </div>
              </section>
              {/*.section2*/}
              <section id="section3">
                <div className="container">
                  <img src="./logo192.png" alt="logo192" className="logo"/>

                  <h2>Контентные виды рекламы</h2>

                  <div className="priceWrap">
                    <a className="price"
                       href="https://docs.google.com/spreadsheets/d/1cMUpE2PvDJs9gRxWLBKoJGsOMiQ9LUl25HCs5ZoiqcE/edit?usp=sharing"
                       target="_blank" rel="noreferrer">ПРАЙС</a>
                  </div>

                  <div className="flex">
                    <div className="item">
                      <div><img src="./img/i1.png" alt="i1"/> PR статья</div>
                      <div><img src="./img/i2.png" alt="i1"/> Нативная статья</div>
                    </div>
                    <div className="item">
                      <div>
                        <img src="./img/i3.png" alt="i1"/> <span>Спецпроекты | </span>
                        <a className="link" href="https://mediakit.orda.kz/examples/1/">Пример</a>
                      </div>
                      <div>
                        <img src="./img/i4.png" alt="i1"/>
                        <div>
                           Промопроекты
                          <span>
                                                (тесты, карточки, детали,
дайджест и пр.)
                                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*.section3*/}
              <section id="contacts">
                <div className="container">
                  <h3>КОНТАКТЫ</h3>
                  <div><strong>ТОО «OrdaMedia.kz» </strong></div>
                  <p>Алматы, БЦ Нурлы-Тау, Блок 4А, офис 102</p>
                  <hr/>
                  <div className="item">
                    <div><strong>Менеджер по рекламе / advertising manager</strong></div>
                    <span>Бекмухаметова Айгуль / Bekmukhametova Aigul</span>
                    <a href="tel:+77089705492">+77089705492</a>
                    <a href="mailto:a.bekmukhametova@orda.kz">a.bekmukhametova@orda.kz</a>
                  </div>
                  <div className="item">
                    <div><strong>Менеджер по рекламе / advertising manager</strong></div>
                    <span>Усанов Берик / Ussanov Berik</span>
                    <a href="tel:+77089739059">+77089739059</a>
                    <a href="mailto:b.ussanov@orda.kz">b.ussanov@orda.kz</a>
                  </div>
                  <div className="item">
                    <div><strong>Менеджер по рекламе / advertising manager</strong></div>
                    <span>Кенес Димаш / Kenges Dimash</span>
                    <a href="tel:+77089738684">+77089738684</a>
                    <a href="mailto:d.kenges@orda.kz">d.kenges@orda.kz</a>
                  </div>
                  <hr/>
                  <div className="map">
                    <img src="./img/contactmap.jpg" alt="contactmap"/>
                  </div>
                  <hr/>
                </div>
              </section>
              {/*.contacts*/}
              <section id="ftr">
                <div className="container">
                  <a href="https://orda.kz/" target="_blank" rel="noreferrer"><img src="./logo192.png"
                                                                                   alt="logo192"
                                                                                   className="logo"/></a>


                </div>
                <div className="copyright">
                  <p>Orda.kz информационно-аналитический портал</p>
                </div>
              </section>
            </div>
            // .content
            : null
        }
      </Fragment>
    );
  }
}

export default App;
